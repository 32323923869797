import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Spinner, CardHeader, CardBody, Button, Table, Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Persona = () => {
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [corporacion, setCorporacion] = useState(1);
    let [datos, setDatos] = useState([]);
    let [colonias, setColonias] = useState([]);
    let [capturando, setCapturando] = useState(false);
    let [id, setId] = useState(0);
    let [apePat, setApePat] = useState("");
    let [apeMat, setApeMat] = useState("");
    let [nombre, setNombre] = useState("");
    let [fechaNac, setFechaNac] = useState(new Date().yyyymmdd('-'));
    let [sexo, setSexo] = useState("");
    let [celular, setCelular] = useState("");
    let [colonia, setColonia] = useState(0);
    let [calle, setCalle] = useState("");
    let [numeroExt, setNumeroExt] = useState("");
    let [cp, setCP] = useState("");
    let [cpAnt, setCPAnt] = useState("?");
    let [maximo, setMaximo] = useState(0);    

    useEffect(() => {
        cargarDatos();
    }, []);

    async function cargarDatos(){
        let url = `${edoGlobal.urlws}/padron/padron/${edoGlobal.usuario.Id}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDatos(datos);
        setCargando(false);
        setMaximo(edoGlobal.usuario.Maximo);
    }
    async function cargarColonias(cp){
        if(cpAnt == cp){
            return;
        }
        if(cp == ""){
            setColonias([]);
            return;
        }
        let url = `${edoGlobal.urlws}/padron/colonias/${cp}`;
        setCargando(true);
        let resp = await fetch(url);
        let cols = await resp.json();
        setColonias(cols);
        setCPAnt(cp);
        setCargando(false);
    }

    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/padron/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setId(i);
        setApePat(d.ApePat);
        setApeMat(d.ApeMat);
        setNombre(d.Nombre);
        setFechaNac(d.FechaNac);
        setSexo(d.Sexo);
        setCelular(d.Celular);
        setCalle(d.Calle);
        setNumeroExt(d.NumeroExt);
        setColonia(d.Colonia);
        setCP(d.CP);
        cargarColonias(d.CP);
        setCargando(false);
        setCapturando(true);
    }
    async function seleccionar(i){
        let url = `${edoGlobal.urlws}/padron/cargar/${i}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
    }
    function guardar(){
        const MySwal = withReactContent(Swal);
        if(apePat == "" || nombre == ""){
            Swal.fire('Atencion',"Especifique apellido y nombre de la persona",'error');
            return;
        }
        if(sexo == ""){
            Swal.fire('Atencion',"Especifique el sexo de la persona",'error');
            return;
        }
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',            
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let dato = {
            Id: id, ApePat:apePat, ApeMat:apeMat,Nombre:nombre,
            FechaNac:fechaNac, Sexo:sexo,
            Celular:celular,Calle:calle, NumeroExt:numeroExt,
            CP:cp, Colonia: colonia, Raiz:edoGlobal.usuario.Id
        }
        let url = `${edoGlobal.urlws}/padron/guardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            setCapturando(false);
            cargarDatos();
        }else
           Swal.fire('Atencion',res,'error');
    }

    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };

    return(
        <div>
            <div style={estiloListado}>
                <table>
                    <tr>
                        <td>Personas registradas : <b>{datos.length}</b> de {maximo}</td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarDatos}><img src="/imagenes/ok.png" title="cargar datos" /></Button></td>
                        <td>&nbsp;{datos.length < maximo  && <Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="agregar persona" /></Button>}</td>
                    </tr>
                </table>
                <div style={{ height: 450, overflow: 'auto' }}>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Link</th>
                                <td className='celdaOpcion'></td>
                                <td className='celdaOpcion'></td>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((o, i) => <tr key={i}>
                                <td>{o.N}</td>
                                <td>{o.S}</td>
                                <td>
                                    <img onClick={() => editar(o.I)} src="/imagenes/edit.png" title="editar" />
                                </td>
                                <td>
                                    <img onClick={() => seleccionar(o.I)} src="/imagenes/ok.png" title="seleccionar" /> 
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className="col">
                        Apellido Pat.
                        <Input size="sm" value={apePat} onChange={(e) => setApePat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Apellido Mat.
                        <Input size="sm" value={apeMat} onChange={(e) => setApeMat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Nombre
                        <Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col-2">
                        Fecha Nac.
                        <Input size="sm" type="date" value={fechaNac} onChange={(e) => setFechaNac(e.target.value)} />
                    </div> 
                    <div className="col-2">
                        Sexo
                        <Input size="sm" type="select" value={sexo} onChange={(e) => setSexo(e.target.value)} >
                            <option value={''}>Espeficique</option>
                            <option value={'M'}>Mujer</option>
                            <option value={'H'}>Hombre</option>
                        </Input>
                    </div> 
                </Row>
                <Row>
                    <div className="col-2">
                        Celular
                        <Input size="sm" value={celular} onChange={(e) => setCelular(e.target.value)} />
                    </div> 
                    <div className="col">
                        Calle
                        <Input size="sm" value={calle} onChange={(e) => setCalle(e.target.value)} />
                    </div> 
                    <div className="col-1">
                        No.Ext.
                        <Input size="sm" value={numeroExt} onChange={(e) => setNumeroExt(e.target.value)} />
                    </div> 
                    <div className="col-1">
                        C.P.
                        <Input size="sm" value={cp} onChange={(e) => setCP(e.target.value)} onBlur={()=> cargarColonias(cp)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col">
                        Colonia
                        <Input size="sm" type="select" value={colonia} onChange={(e) => setColonia(e.target.value)} >
                            <option value={0}>Espeficique</option>
                            {colonias.map((o,i)=><option key={o.I} value={o.I}>{o.N}</option>)}
                        </Input>
                    </div> 
                </Row>
                <Row>
                    <div className='col-4'>
                        <br/>
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="danger" outline onClick={()=>setCapturando(false)}> Cancelar </Button>
                    </div>
                </Row>
            </div>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
    )
}